<template>
  <section v-if="getCaseStudiesSectionData" ref="case-studies-section" class="section">
    <h2 class="title" data-aos="fade-up">{{ getCaseStudiesSectionData.title }}</h2>

    <p class="subtitle --narrow" data-aos="fade-up">{{ getCaseStudiesSectionData.subtitle }}</p>
    <article v-if="showDynamicComponents" class="case-studies">
      <div
        v-for="(item, index) in caseStudiesData"
        :key="`${item}__${index}`"
        class="case-study-aos"
        data-aos="fade-up"
      >
        <div
          class="case-studies-item"
          @mouseenter="playVid(item.id)"
          @mouseleave="mouseLeave($event, item.id)"
          @mousemove="mouseMove"
          @click="
            $router.push({
              name: `${SINGLE_PORTFOLIO.slug}__${getCurrentLanguage}`,
              params: { slug: item.slug },
            })
          "
        >
          <div class="img-container">
            <video
              v-if="!isMobile"
              :id="`player_${item.id}`"
              v-lazy-load :data-src="item.caseStudiesBoxVideo.mediaItemUrl"
              type="video/mp4"
              height="600"
              class="player --hide"
              muted
              loop
              preload="none"
            ></video>
            <AppImage
              :image="item.caseStudiesBoxImage.srcSet"
              :alt="item.caseStudiesBoxImage.altText"
              :sizes="item.caseStudiesBoxImage.sizes"
              :blurred-preview="item.caseStudiesBoxImage.imageBlur"
              class="img"
            />
          </div>
          <div class="content">
            <p class="subtitle">{{ item.caseStudiesBoxSubtitle }}</p>
            <p class="title">
              {{ item.clientName }}
              <span class="icon-container"><ArrowLinearIcon /></span>
            </p>
          </div>
        </div>
      </div>
    </article>

    <div>
      <AppButton to="case-studies" title="case studies"
        >{{ getCaseStudiesSectionData.acfButtonContent }} <ArrowLinearIcon
      /></AppButton>
    </div>
  </section>
</template>

<script>
import { isMobile } from '@/utils/mobile-device-detect.js'
import { mapGetters } from 'vuex'
import { eventPath } from '@/utils/event-path.js'

import { ROUTES } from '@/utils/routes'

export default {
  name: 'AppCaseStudies',
  components: {
    AppButton: () => import('@/components/common/AppButton'),
    ArrowLinearIcon: () => import('@/components/icons/ArrowLinearIcon'),
    AppImage: () => import('@/components/common/AppImage'),
  },
  props: {
    showDynamicComponents: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      elements: [],
      constrain: 600,
      mouseleaveTimeout: null,
      activeItem: null,
      SINGLE_PORTFOLIO: ROUTES.SINGLE_PORTFOLIO,
      isMobile,
    }
  },

  computed: {
    ...mapGetters(['getCaseStudiesSectionData', 'getCurrentLanguage']),
    caseStudiesData() {
      if (!this.getCaseStudiesSectionData) {
        return null
      } else {
        return this.getCaseStudiesSectionData.caseStudyItem.map((item) => {
          return {
            ...item.caseStudy[0].caseStudyItem[this.getCurrentLanguage],
            ...item.caseStudy[0].caseStudyItem.general,
            id: item.caseStudy[0].id,
            slug: item.caseStudy[0].slug,
          }
        })
      }
    },
  },
  methods: {
    async playVid(id) {
      if (isMobile) return
      const player = document.querySelector(`video[id="player_${id}"]`)
      if (!player) return
      player.classList.remove('--hide')
      await player.play()
      if (!player?.paused) this.videoPlaying = id
    },
    stopVid(id) {
      const player = document.querySelector(`video[id="player_${id}"]`)
      if (!player) return
      player.classList.add('--hide')
      if (!player?.paused && this.videoPlaying === id) {
        player.pause()
        this.videoPlaying = null
      }
    },
    transforms(x, y, el) {
      const box = el.getBoundingClientRect()
      const calcX = -(y - box.y - box.height / 3) / this.constrain
      const calcY = (x - box.x - box.width / 3) / this.constrain

      return `perspective(84px) rotateX(${calcX}deg) rotateY(${calcY}deg)`
    },
    transformElement(el, xyEl) {
      el.style.transform = this.transforms.apply(null, xyEl)
    },
    resetTransform(el) {
      if (!el) return
      window.requestAnimationFrame(() => {
        el.style.transform = `perspective(500px) rotateX(0) rotateY(0) scale(1)`
      })
    },
    mouseMove(e) {
      const elPath = eventPath(e)
      const el = elPath?.find((item) => item.classList.contains('case-studies-item'))
      if (!el) return
      if (this.activeItem !== el) this.resetTransform(this.activeItem)
      this.activeItem = el

      const xy = [e.clientX, e.clientY]
      const position = xy.concat(el)

      window.requestAnimationFrame(() => {
        this.transformElement(el, position)
      })
    },
    mouseLeave(e, id) {
      const elPath = eventPath(e)
      const el = elPath?.find((item) => item.classList.contains('case-studies-item'))

      this.stopVid(id)

      if (this.mouseleaveTimeout) clearTimeout(this.mouseleaveTimeout)

      this.mouseleaveTimeout = setTimeout(() => {
        if (el) this.resetTransform(el)
      }, 200)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/components/common/sections/AppCaseStudiesSection';
</style>
